const customerInfo = {
  phone: "0979999328",
  fullName: "Nguyễn Hà Đông",
  rankName: "Thân thiết",
  currentPoint: "705",
};

const BannerButton = [
  {
    Thumbnail:
      "https://my1.itel.vn//UploadTemp/8a3a492b-1bcc-4e36-8caf-335f082eebf4.jpg",
    ThumbnailMobile:
      "https://my1.itel.vn//UploadTemp/dd125bf4-af1d-4dc4-9f75-c4828e7b8e34.jpg",
    Url: "banner button itel club",
    Id: 35,
  },
];

const ButtonBanners = [
  {
    Thumbnail:
      "https://my1.itel.vn//UploadTemp/8a3a492b-1bcc-4e36-8caf-335f082eebf4.jpg",
    ThumbnailMobile:
      "https://my1.itel.vn//UploadTemp/dd125bf4-af1d-4dc4-9f75-c4828e7b8e34.jpg",
    Url: "banner button itel club",
    Id: 35,
  },
];

const BannerMain = [
  {
    Thumbnail:
      "https://my1.itel.vn//UploadTemp/69349725-c06f-4fec-af7c-f080e22afa19.jpg",
    ThumbnailMobile:
      "https://my1.itel.vn//UploadTemp/8178621d-4cbc-4690-8dad-d1b71aee1a10.jpg",
    Url: "banner itel club",
    Id: 33,
  },
];

const BannerPromotion = [
  {
    Thumbnail:
      "https://my1.itel.vn//UploadTemp/76db73ac-edcf-4f2f-9719-d305c5fd4b84.png",
    ThumbnailMobile:
      "https://my1.itel.vn//UploadTemp/635ae442-9c78-4251-9894-637b28807ac1.png",
    Url: "Banner khuyến mãi iTel Club",
    Id: 34,
  },
];

const getExchangedVoucher = {
  status: 200,
  error: "SUCCESS",
  data: {
    content: [
      {
        id: "5444",
        brand: "552",
        brand_id: "552",
        cat_id: "12",
        cat_title: "Mua Sắm Online",
        gift_id: "2486",
        title: "[UrBox Voucher] Grab Food 10.000 đ",
        type: "1",
        price: "10000",
        view: "1743",
        quantity: "856",
        stock: 1,
        image:
          "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/06/21/640/1624245472_60d004e0b327a.png",
        images: {
          0: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/06/21/1624245472_60d004e0b327a.png",
          80: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/06/21/80/1624245472_60d004e0b327a.png",
          160: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/06/21/160/1624245472_60d004e0b327a.png",
          320: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/06/21/320/1624245472_60d004e0b327a.png",
          640: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/06/21/640/1624245472_60d004e0b327a.png",
          square:
            "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/06/21/square/1624245472_60d004e0b327a.png",
        },
        images_rectangle: {
          0: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/06/21/1624245472_60d004e42b7b5.png",
          80: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/06/21/80/1624245472_60d004e42b7b5.png",
          160: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/06/21/160/1624245472_60d004e42b7b5.png",
          320: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/06/21/320/1624245472_60d004e42b7b5.png",
          640: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/06/21/640/1624245472_60d004e42b7b5.png",
          square:
            "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/06/21/square/1624245472_60d004e42b7b5.png",
        },
        expire_duration: "90 ngày",
        code_display: "QR code",
        code_display_type: 1,
        brandLogoLoyalty:
          "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/02/03/160/1612343773_601a69dd8c615.png",
        brandImage:
          "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/02/03/640/1612343773_601a69dd8c615.png",
        brand_name: "Grab Food",
        parent_cat_id: "4",
        usage_check: 0,
        code_quantity: "856",
        transaction_id: 155198,
        voucher_code: "M0BDVD0S",
        expired_date: "2022-10-22T00:00:00+07:00",
        image_code:
          "https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=M0BDVD0S",
      },
      {
        id: "5178",
        brand: "554",
        brand_id: "554",
        cat_id: "16",
        cat_title: "Làm Đẹp - Sức Khoẻ",
        gift_id: "2538",
        title: "[UrBox Voucher] The Body Shop 1 đ",
        type: "1",
        price: "1",
        view: "213",
        quantity: "27",
        stock: 1,
        image:
          "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/640/1617598820_606a99647b7a2.png",
        images: {
          0: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/1617598820_606a99647b7a2.png",
          80: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/80/1617598820_606a99647b7a2.png",
          160: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/160/1617598820_606a99647b7a2.png",
          320: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/320/1617598820_606a99647b7a2.png",
          640: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/640/1617598820_606a99647b7a2.png",
          square:
            "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/square/1617598820_606a99647b7a2.png",
        },
        images_rectangle: {
          0: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/1617598820_606a99673b8a2.png",
          80: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/80/1617598820_606a99673b8a2.png",
          160: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/160/1617598820_606a99673b8a2.png",
          320: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/320/1617598820_606a99673b8a2.png",
          640: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/640/1617598820_606a99673b8a2.png",
          square:
            "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/square/1617598820_606a99673b8a2.png",
        },
        expire_duration: "60 ngày",
        code_display: "QR code",
        code_display_type: 1,
        brandLogoLoyalty:
          "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/02/25/160/1614238936_603754d8d9c8f.png",
        brandImage:
          "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/02/25/640/1614238936_603754d8d9c8f.png",
        brand_name: "The Body Shop",
        parent_cat_id: "5",
        usage_check: 1,
        code_quantity: "27",
        transaction_id: 158223,
        voucher_code: "UB053332132",
        expired_date: "2022-07-16T00:00:00+07:00",
        image_code:
          "https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=UB053332132",
      },
      {
        id: "5178",
        brand: "554",
        brand_id: "554",
        cat_id: "16",
        cat_title: "Làm Đẹp - Sức Khoẻ",
        gift_id: "2538",
        title: "[UrBox Voucher] The Body Shop 1 đ",
        type: "1",
        price: "1",
        view: "213",
        quantity: "27",
        stock: 1,
        image:
          "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/640/1617598820_606a99647b7a2.png",
        images: {
          0: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/1617598820_606a99647b7a2.png",
          80: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/80/1617598820_606a99647b7a2.png",
          160: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/160/1617598820_606a99647b7a2.png",
          320: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/320/1617598820_606a99647b7a2.png",
          640: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/640/1617598820_606a99647b7a2.png",
          square:
            "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/square/1617598820_606a99647b7a2.png",
        },
        images_rectangle: {
          0: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/1617598820_606a99673b8a2.png",
          80: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/80/1617598820_606a99673b8a2.png",
          160: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/160/1617598820_606a99673b8a2.png",
          320: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/320/1617598820_606a99673b8a2.png",
          640: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/640/1617598820_606a99673b8a2.png",
          square:
            "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/square/1617598820_606a99673b8a2.png",
        },
        expire_duration: "60 ngày",
        code_display: "QR code",
        code_display_type: 1,
        brandLogoLoyalty:
          "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/02/25/160/1614238936_603754d8d9c8f.png",
        brandImage:
          "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/02/25/640/1614238936_603754d8d9c8f.png",
        brand_name: "The Body Shop",
        parent_cat_id: "5",
        usage_check: 1,
        code_quantity: "27",
        transaction_id: 158509,
        voucher_code: "UB105240886",
        expired_date: "2022-07-22T00:00:00+07:00",
        image_code:
          "https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=UB105240886",
      },
    ],
    totalElements: 3,
    totalPages: 1,
    numberOfElemetns: 3,
    number: 2,
    size: 8,
  },
};

const PromotionForYou = "* Các ưu đãi tương ứng với hạng hội viên của bạn";
const RecievedPromotion = "* Các ưu đãi bạn đã nhận và còn hạn sử dụng";
const VipVoucher = "* Các ưu đãi dành cho hội viên Kim cương";

const getFillteredVoucher = {
  TotalPage: 22,
  TotalItem: 128,
  CurrentPage: 1,
  IsNext: true,
  Data: [
    {
      id: 89,
      title: "[UrBox Voucher] The Coffee House 50.000 đ",
      price: "50000",
      image:
        "https://632466.sgp1.digitaloceanspaces.com/_img_server/2022/03/17/640/1647522350_6233322ebf67e.png",
      brandImage:
        "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/07/07/640/1625634148_60e535649ca63.png",
      point: 5000,
      content: null,
      note: null,
      expired_date: "0001-01-01T00:00:00",
      expire_duration: "60 ngày",
      voucher_id: 1084,
      cat_id: 118,
      images_rectangle: {
        0: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2022/03/17/1647522350_623332316a8b2.png",
        80: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2022/03/17/80/1647522350_623332316a8b2.png",
        160: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2022/03/17/160/1647522350_623332316a8b2.png",
        320: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2022/03/17/320/1647522350_623332316a8b2.png",
        640: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2022/03/17/640/1647522350_623332316a8b2.png",
        square:
          "https://632466.sgp1.digitaloceanspaces.com/_img_server/2022/03/17/square/1647522350_623332316a8b2.png",
      },
    },
    {
      id: 90,
      title: "[UrBox Voucher] The Coffee House 100.000 đ",
      price: "100000",
      image:
        "https://632466.sgp1.digitaloceanspaces.com/_img_server/2022/03/17/640/1647522370_623332426de61.png",
      brandImage:
        "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/07/07/640/1625634148_60e535649ca63.png",
      point: 10000,
      content: null,
      note: null,
      expired_date: "0001-01-01T00:00:00",
      expire_duration: "60 ngày",
      voucher_id: 1083,
      cat_id: 118,
      images_rectangle: {
        0: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2022/03/17/1647522370_62333244c402b.png",
        80: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2022/03/17/80/1647522370_62333244c402b.png",
        160: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2022/03/17/160/1647522370_62333244c402b.png",
        320: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2022/03/17/320/1647522370_62333244c402b.png",
        640: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2022/03/17/640/1647522370_62333244c402b.png",
        square:
          "https://632466.sgp1.digitaloceanspaces.com/_img_server/2022/03/17/square/1647522370_62333244c402b.png",
      },
    },
    {
      id: 493,
      title: "[UrBox Voucher] GrabBike, GrabCar 50.000 đ",
      price: "50000",
      image:
        "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/09/09/640/1631183373_6139e20def0cd.png",
      brandImage:
        "https://632466.sgp1.digitaloceanspaces.com/_img_server/2020/04/01/640/1585716414_5e841cbeccda4.png",
      point: 5000,
      content: null,
      note: null,
      expired_date: "0001-01-01T00:00:00",
      expire_duration: "90 ngày",
      voucher_id: 1081,
      cat_id: 115,
      images_rectangle: {
        0: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/09/09/1631183373_6139e21130d5d.png",
        80: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/09/09/80/1631183373_6139e21130d5d.png",
        160: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/09/09/160/1631183373_6139e21130d5d.png",
        320: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/09/09/320/1631183373_6139e21130d5d.png",
        640: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/09/09/640/1631183373_6139e21130d5d.png",
        square:
          "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/09/09/square/1631183373_6139e21130d5d.png",
      },
    },
    {
      id: 676,
      title: "[UrBox Voucher] JOLLIBEE 75.000 đ",
      price: "75000",
      image:
        "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/05/04/640/1620113232_6090f750e4f5c.png",
      brandImage:
        "https://632466.sgp1.digitaloceanspaces.com/_img_server/2020/12/01/640/1606789364_5fc5a8f4de886.png",
      point: 7500,
      content: null,
      note: null,
      expired_date: "0001-01-01T00:00:00",
      expire_duration: "90 ngày",
      voucher_id: 1078,
      cat_id: 10,
      images_rectangle: {
        0: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/05/04/1620113232_6090f75657623.png",
        80: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/05/04/80/1620113232_6090f75657623.png",
        160: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/05/04/160/1620113232_6090f75657623.png",
        320: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/05/04/320/1620113232_6090f75657623.png",
        640: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/05/04/640/1620113232_6090f75657623.png",
        square:
          "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/05/04/square/1620113232_6090f75657623.png",
      },
    },
    {
      id: 717,
      title: "[UrBox Voucher] Grand Castella 100.000 đ",
      price: "100000",
      image:
        "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/07/07/640/1625639682_60e54b02e94eb.png",
      brandImage:
        "https://632466.sgp1.digitaloceanspaces.com/_img_server/2020/04/01/640/1585716453_5e841ce530724.png",
      point: 10000,
      content: null,
      note: null,
      expired_date: "0001-01-01T00:00:00",
      expire_duration: "90 ngày",
      voucher_id: 1828,
      cat_id: 117,
      images_rectangle: {
        0: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/07/07/1625639682_60e54b080dff2.png",
        80: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/07/07/80/1625639682_60e54b080dff2.png",
        160: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/07/07/160/1625639682_60e54b080dff2.png",
        320: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/07/07/320/1625639682_60e54b080dff2.png",
        640: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/07/07/640/1625639682_60e54b080dff2.png",
        square:
          "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/07/07/square/1625639682_60e54b080dff2.png",
      },
    },
    {
      id: 911,
      title: "[UrBox Voucher] Nét Huế 75.000 đ",
      price: "75000",
      image:
        "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/05/04/640/1620109955_6090ea8344fe1.png",
      brandImage:
        "https://632466.sgp1.digitaloceanspaces.com/_img_server/2020/04/01/640/1585718895_5e84266f80402.png",
      point: 7500,
      content: null,
      note: null,
      expired_date: "0001-01-01T00:00:00",
      expire_duration: "90 ngày",
      voucher_id: 1826,
      cat_id: 10,
      images_rectangle: {
        0: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/05/04/1620109955_6090ea8603a97.png",
        80: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/05/04/80/1620109955_6090ea8603a97.png",
        160: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/05/04/160/1620109955_6090ea8603a97.png",
        320: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/05/04/320/1620109955_6090ea8603a97.png",
        640: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/05/04/640/1620109955_6090ea8603a97.png",
        square:
          "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/05/04/square/1620109955_6090ea8603a97.png",
      },
    },
  ],
};
const getVoucherCategory = {
  status: 200,
  error: "SUCCESS",
  data: [
    {
      id: 5,
      name: "Làm Đẹp - Sức Khoẻ",
      title: "Làm Đẹp - Sức Khoẻ",
      isTranslate: false,
      value: 5,
    },
    {
      id: 107,
      name: "Tiện Ích",
      title: "Tiện Ích",
      isTranslate: false,
      value: 107,
    },
    {
      id: 6,
      name: "Giải Trí",
      title: "Giải Trí",
      isTranslate: false,
      value: 6,
    },
    { id: 4, name: "Mua Sắm", title: "Mua Sắm", isTranslate: false, value: 4 },
    { id: 9, name: "Đồ Uống", title: "Đồ Uống", isTranslate: false, value: 9 },
    { id: 8, name: "Đồ Ăn", title: "Đồ Ăn", isTranslate: false, value: 8 },
    {
      id: 2,
      name: "Evoucher",
      title: "Evoucher",
      isTranslate: false,
      value: 2,
    },
    {
      id: 136,
      name: "Quà Vật Lý",
      title: "Quà Vật Lý",
      isTranslate: false,
      value: 136,
    },
  ],
};

const getCities = {
  status: 200,
  error: "SUCCESS",
  data: [
    {
      id: 3,
      name: "Bạc Liêu",
      title: "Bạc Liêu",
      isTranslate: false,
      value: 3,
    },
    {
      id: 5,
      name: "Bắc Giang",
      title: "Bắc Giang",
      isTranslate: false,
      value: 5,
    },
    {
      id: 6,
      name: "Bắc Ninh",
      title: "Bắc Ninh",
      isTranslate: false,
      value: 6,
    },
    {
      id: 9,
      name: "Bình Định",
      title: "Bình Định",
      isTranslate: false,
      value: 9,
    },
    {
      id: 10,
      name: "Bình Phước",
      title: "Bình Phước",
      isTranslate: false,
      value: 10,
    },
    {
      id: 11,
      name: "Bình Thuận",
      title: "Bình Thuận",
      isTranslate: false,
      value: 11,
    },
    { id: 12, name: "Cà Mau", title: "Cà Mau", isTranslate: false, value: 12 },
    {
      id: 13,
      name: "Cao Bằng",
      title: "Cao Bằng",
      isTranslate: false,
      value: 13,
    },
    {
      id: 14,
      name: "Cần Thơ",
      title: "Cần Thơ",
      isTranslate: false,
      value: 14,
    },
  ],
};

const listRankLoyalty = [
  { id: 0, name: "Tất cả", title: "tất cả", isTranslate: false, value: 0 },
  { id: 1, name: "Miễn phí", title: "Miễn phí", isTranslate: false, value: 1 },
  {
    id: 2,
    name: "Dưới 1.000 điểm",
    title: "Dưới 1.000 điểm",
    isTranslate: false,
    value: 2,
  },
  {
    id: 3,
    name: "Từ 1.000 - 5.000 điểm ",
    title: "Từ 1.000 - 5.000 điểm ",
    isTranslate: false,
    value: 3,
  },
  {
    id: 4,
    name: "Từ 5.000 - 10.000 điểm",
    title: "Từ 5.000 - 10.000 điểm",
    isTranslate: false,
    value: 4,
  },
  {
    id: 5,
    name: "Trên 10.000 điểm",
    title: "Trên 10.000 điểm",
    isTranslate: false,
    value: 5,
  },
];

const categories = [
  {
    Id: 2,
    OrderNumber: 1,
    Name: "Giới thiệu",
    Slug: "gioi-thieu",
    Children: [],
    IsActive: true,
  },
  {
    Id: 3,
    OrderNumber: 2,
    Name: "Ưu đãi VIP",
    Slug: "uu-dai-vip",
    Children: [],
    IsActive: false,
  },
  {
    Id: 1,
    OrderNumber: 3,
    Name: "Tin tức - Thông báo",
    Slug: "tin-tuc---thong-bao",
    Children: [],
    IsActive: false,
  },
];

const getBlogLoyaltyByCategory = {
  TotalPage: 1,
  TotalItem: 2,
  CurrentPage: 1,
  IsNext: false,
  Data: [
    {
      Slug: "itel-club---chuong-trinh-cham-soc-khach-hang-dac-biet-cua-mang-di-dong-itel",
      Brief:
        "ITEL CLUB là chương trình tri ân và nâng cao lợi ích cho khách hàng thân thiết của Mạng di động iTel. Thông qua chương trình này, khách hàng sẽ có cơ hội tích lũy thật nhiều điểm thưởng và đổi lấy ngàn phần quà có giá trị.",
      ReadTime: 15,
      Thumbnail:
        "https://my1.itel.vn//UploadTemp/df3d7ed5-64af-46da-bb56-15b416793ee3.png",
      AuthorIcon:
        "https://my1.itel.vn//UploadTemp/6119f046-7345-4359-b0b0-8e31995ecd33.jpg",
      Author: "iTel Club",
      Title:
        "iTel Club - Chương trình chăm sóc khách hàng đặc biệt của Mạng di động iTel",
      OrderNumber: 1,
      Id: 1,
      ParentName: "Giới thiệu",
      BlogLoyaltyCategorySlug: "gioi-thieu",
    },
    {
      Slug: "huong-dan-nhan-va-su-dung-uu-dai-itel-club",
      Brief:
        "ITEL CLUB là chương trình tri ân và nâng cao lợi ích cho khách hàng thân thiết của Mạng di động iTel. Xem thêm hướng Hướng dẫn nhận và sử dụng Ưu đãi iTel Club để rinh ngàn phần quà hấp dẫn!",
      ReadTime: 15,
      Thumbnail:
        "https://my1.itel.vn//UploadTemp/cd5dee2c-2692-41f0-8ef8-e08c9a07dbe4.png",
      AuthorIcon:
        "https://my1.itel.vn//UploadTemp/30bd517e-f758-4dca-afbb-44715f2adee6.png",
      Author: "iTel Club",
      Title: "Hướng dẫn nhận và sử dụng Ưu đãi iTel Club",
      OrderNumber: 2,
      Id: 5,
      ParentName: "Giới thiệu",
      BlogLoyaltyCategorySlug: "gioi-thieu",
    },
  ],
};

const getVoucherDetail = {
  status: 200,
  error: "SUCCESS",
  data: {
    id: 5178,
    code: null,
    expired_date: "2022-07-16T00:00:00+07:00",
    title: "[UrBox Voucher] The Body Shop 1 đ",
    price: "1",
    image:
      "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/640/1617598820_606a99647b7a2.png",
    brandImage:
      "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/02/25/160/1614238936_603754d8d9c8f.png",
    point: 2,
    content:
      "The Body Shop là thương hiệu mỹ phẩm tiên phong có nguồn gốc tự nhiên. Các sản phẩm từ The Body Shop đều là sản phẩm 100% thiên nhiên, được khai thác từ nhiều nơi trên thế giới. Hiện tại, The Body Shop có hơn 3,000 cửa hàng trên 70 quốc gia cùng hơn 22,000 nhân viên đồng hành trên hành trình đem sự hòa trộn vẻ đẹp nhân đạo độc đáo, cùng một chút hài hước và mục đích nghiêm túc đến cả thế giới.",
    note: '<ul><li>eGift có thể sử dụng tại tất cả các cửa hàng <b>The Body Shop</b>. <b>(Voucher không được áp dụng tại một số TTTM)</b>. Vui lòng xem thông tin cửa hàng tại "Vị trí cửa hàng".</li><li><b>Quý khách có thể sử dụng nhiều eGift trên cùng 01 hoá đơn.</b></li><li><b>eGift được áp dụng cùng các chương trình khuyến mãi khác.</b></li><li>eGift được xuất ra sẽ không được đổi trả dưới mọi hình thức.</li><li>eGift chỉ có giá trị sử dụng một lần. Không chấp nhận eGift quá hạn sử dung, trạng thái “Đã sử dụng”.</li><li>Vui lòng xuất trình mã eGift cho nhân viên tại quầy trước khi thanh toán để được áp dụng eGift.</li><li>eGift sẽ không được hoàn lại tiền thừa và không có giá trị quy đổi thành tiền mặt. Khách hàng có thể được yêu cầu trả thêm tiền nếu sử dụng quá giá trị của eGift.</li><li>Khách hàng có trách nhiệm bảo mật thông tin eGift sau khi đặt mua. UrBox sẽ không chịu trách nhiệm hoàn trả các eGift bị mất hoặc ở trạng thái “Đã sử dụng” sau thời gian eGift được xuất ra với bất kì lý do gì.</li><li>UrBox không chịu trách nhiệm đối với chất lượng của sản phẩm hoặc dịch vụ được cung cấp cũng như đối với các tranh chấp về sau giữa khách hàng và <b>The Body Shop.</b></li><li>UrBox có quyền sửa chữa hoặc thay đổi điều khoản và điều kiện mà không thông báo trước.</li><li>Quý khách vui lòng liên hệ <b>Hotline UrBox: 1900 299 232</b> (từ 8h-22h hàng ngày, bao gồm Lễ Tết) để được hỗ trợ.</li></ul>',
    voucher_code: "UB053332132",
    image_code:
      "https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=UB053332132",
    cat_id: 16,
    expire_duration: "60 ngày",
    images_rectangle: {
      0: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/1617598820_606a99673b8a2.png",
      80: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/80/1617598820_606a99673b8a2.png",
      160: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/160/1617598820_606a99673b8a2.png",
      320: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/320/1617598820_606a99673b8a2.png",
      640: "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/640/1617598820_606a99673b8a2.png",
      square:
        "https://632466.sgp1.digitaloceanspaces.com/_img_server/2021/04/05/square/1617598820_606a99673b8a2.png",
    },
  },
};

export {
  customerInfo,
  ButtonBanners,
  BannerMain,
  BannerPromotion,
  BannerButton,
  getExchangedVoucher,
  PromotionForYou,
  RecievedPromotion,
  VipVoucher,
  getFillteredVoucher,
  getVoucherCategory,
  getCities,
  listRankLoyalty,
  categories,
  getBlogLoyaltyByCategory,
  getVoucherDetail,
};
