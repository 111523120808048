<template>
  <div class="fs-section fs-banner banner__club">
    <div v-if="BannerMain" class="fs-inr banner-inr">
      <div class="banner-slider swiper-container">
        <swiper :slides-per-view="1" :space-between="50">
          <swiper-slide>
            <a class="fs-pic" :href="BannerMain.Url"><img loading="lazy" alt="Banner Mobile" class="fs-sp"
                :src="BannerMain.ThumbnailMobile" /></a>
            <a class="fs-pic desktop-img" :href="BannerMain.Url"><img loading="lazy" alt="Banner Desktop" class="fs-pc"
                :src="BannerMain.Thumbnail" /></a>
          </swiper-slide>
        </swiper>
      </div>
      <!-- nav bar -->
      <div class="fs-section section__nav">
        <div class="fs-inr nav__inr">
          <div class="nav-news">
            <ul>
              <li class="active">
                <a href="javascript:void(0);">
                  <h2>ITEL ƯU ĐÃI THÀNH VIÊN</h2>
                </a>
              </li>
              <li>
                <a href="/club-blog">
                  <h2>ITEL CHÍNH SÁCH/ TIN TỨC ƯU ĐÃI</h2>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Login now PC -->
      <div v-if="!customerInfo" class="fs-inr fs-pc">
        <b-card class="p-0 mx-1">
          <b-row>
            <b-col md="3">
              <div class="discover-banner">
                <img class="banner-img" loading="lazy" :src="BannerButton.Thumbnail"
                  alt="Đăng nhập ngay để khám phá ưu đãi dành riêng cho bạn" />
              </div>
            </b-col>
            <b-col md="6">
              <div class="discover__title">
                <p>Đăng nhập ngay để khám phá ưu đãi dành riêng cho bạn</p>
              </div>
            </b-col>
            <b-col md="3">
              <div class="discover-buts">
                <button class="banner-but" @click="onShowLogin()">
                  Đăng nhập ngay
                </button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <!-- Login now MB -->
      <div v-if="!customerInfo" class="fs-sp mx-1">
        <b-card class="p-0 my-1">
          <b-row>
            <b-col cols="5">
              <div class="discover-banner">
                <img class="banner-img" loading="lazy" :src="BannerButton.Thumbnail"
                  alt="Đăng nhập ngay để khám phá ưu đãi dành riêng cho bạn" />
              </div>
            </b-col>

            <b-col cols="7">
              <b-row>
                <div class="discover__title">
                  <p>Đăng nhập ngay để khám phá ưu đãi dành riêng cho bạn</p>
                </div>
              </b-row>
              <b-row>
                <div class="discover-buts">
                  <button class="banner-but" @click="onShowLogin()">
                    Đăng nhập ngay
                  </button>
                </div>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <!-- promotion code -->
      <div class="fs-section section__voucher fs-pc">
        <div class="fs-inr voucher__inr">
          <div class="voucher__box">
            <div class="voucher__slider swiper-container">
              <swiper>
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="fs-pic">
                      <img loading="lazy" :src="BannerPromotion.Thumbnail" alt="Banner Desktop" />
                    </div>
                  </div>
                </div>
              </swiper>
            </div>
            <!-- <div class="swiper-pagination"></div> -->
          </div>
        </div>
      </div>
      <!-- user đăng nhập loyalty -->
      <div v-if="customerInfo" class="fs-section section__member fs-inr">
        <div v-if="customerInfo.rankName" class="member__inr">
          <div class="member__item">
            <p>Thuê bao {{ lstore().data }}</p>
            <p class="member_name">{{ customerInfo.fullName }}</p>
          </div>
          <!--start__red, start__silver, start__yellow, start__diamond -->
          <div class="member__item has__start start__red">
            <p>Thành viên</p>
            <p class="color__red">
              {{
                customerInfo.rankName ? customerInfo.rankName : "Đang cập nhật"
              }}
            </p>
          </div>

          <div class="member__item">
            <p>Điểm ưu đãi</p>
            <p class="color__red">
              {{
                customerInfo.currentPoint
                ? customerInfo.currentPoint
                : "Đang cập nhật "
              }}
              điểm
            </p>
          </div>
        </div>
        <div v-else class="member__inr">
          <p class="member_name_comom">
            {{ customerInfo.fullName }} thuê bao {{ lstore().data }}
          </p>
        </div>
      </div>

      <!-- user đăng nhập loyalty -->

      <div class="fs-inr promotion__inr">
        <div v-if="dataExchangedVoucher.length > 0">
          <div class="endow__block">
            <div class="endow__title">
              <h2>ƯU ĐÃI ĐÃ NHẬN</h2>
              <p>{{ RecievedPromotion }}</p>
            </div>
            <div class="endow__box">
              <swiper class="swiper-wrapper" :slides-per-view="isMobile ? 1 : 2" :space-between="20" @swiper="onSwiper"
                @slideChange="onSlideChange">
                <swiper-slide v-for="item in dataExchangedVoucher" :key="item.transaction_id">
                  <div class="endor__item" @click="showExchangedVoucherDetail(item.transaction_id)">
                    <div class="fs-bg cmBg endor__bg" :style="{ backgroundImage: `url(${item.image})` }"></div>
                    <div class="endor__txt">
                      <h3>{{ item.title }}</h3>
                      <div class="endor__date">
                        {{ formatDate(item.expired_date) }}
                      </div>
                    </div>

                    <div class="endor__value">
                      {{ formatPrice(item.price) }}
                    </div>
                  </div>
                </swiper-slide>
              </swiper>

              <!-- Paging Mobile -->
              <div class="fs-sp">
                <div class="row justify-content-between align-items-center">
                  <b-button variant="flat-white" class="btn-icon" :disabled="activeIndexSide == 0" @click="onPrevVocuher">
                    <feather-icon size="25" color="white" icon="ArrowLeftIcon" />
                  </b-button>

                  <div v-if="snapGrid.length > 0" class="row">
                    <div v-for="(snap, index) in snapGrid" :key="index" class="ml-1">
                      <div :class="`${currentSnap == index ? 'snap-active' : 'snap'
                        }`" @click="onSnap(index)"></div>
                    </div>
                  </div>
                  <b-button variant="flat-white" class="btn-icon" :disabled="isEndSlide" @click="onNextVoucher">
                    <feather-icon size="25" color="white" icon="ArrowRightIcon" />
                  </b-button>
                </div>
              </div>
              <!--  Paging PC -->
              <div class="fs-pc">
                <div v-if="snapGrid.length > 0" class="row justify-content-center mt-5">
                  <div v-for="(snap, index) in snapGrid" :key="index" class="ml-1 fs-pc">
                    <div :class="`${currentSnap == index ? 'snap-active' : 'snap'
                      }`" @click="onSnap(index)"></div>
                  </div>
                </div>
              </div>
              <!--  Padding PC -->
              <div class="fs-pc">
                <div class="swiper-prev">
                  <b-button variant="flat-secondary" class="btn-icon" :disabled="activeIndexSide == 0"
                    @click="onPrevVocuher">
                    <feather-icon size="35" color="white" icon="ArrowLeftCircleIcon" />
                  </b-button>
                </div>
              </div>
              <div class="fs-pc">
                <div class="swiper-next">
                  <b-button variant="flat-secondary" class="btn-icon" :disabled="isEndSlide" @click="onNextVoucher">
                    <feather-icon color="white" size="35" icon="ArrowRightCircleIcon" />
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Before Login-->
      <div class="fs-inr promotion__inr">
        <div class="promotion__top">
          <div v-if="!customerInfo" class="promotion__title">
            <h1>Danh sách ưu đãi</h1>
          </div>

          <div v-if="customerInfo" class="promotion__title">
            <h1>ƯU ĐÃI DÀNH CHO BẠN</h1>
            <p>{{ PromotionForYou }}</p>
          </div>

          <div class="promotion__sort">
            <div class="sort__col">
              <Select2 class="select2-custom" :settings="{ minimumResultsForSearch: -1 }" :placeholder="'Phân loại'"
                :options="getVoucherCategory" v-model="serverParams.categoryId" @change="serverParams.currentPage = 1" />
            </div>
            <div class="sort__col">
              <div class="sort__search">
                <input class="sort__input" type="text" placeholder="Tìm kiếm" v-model="serverParams.search" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="rowsVoucher.length > 0">
          <b-row cols="2" cols-lg="3" class="wrap-promation">
            <b-col class="warp-col" v-for="(promotion, index) in rowsVoucher" :key="index">
              <b-card class="m-0 p-0">
                <div class="card-body">
                  <a :href="`/club/voucher-detail/${promotion.voucher_id}`" class="promotion__item">
                    <div class="promotion__money">
                      {{ formatPrice(promotion.price) }} đ
                    </div>
                    <div class="fs-bg cmBg promotion__bg" :style="{ backgroundImage: `url(${promotion.image})` }"></div>
                    <div class="fs-txt promotion__txt">
                      <h3>{{ promotion.title }}</h3>
                    </div>
                    <div class="core__block">
                      <div class="fs-bg cmBg core__bg" :style="{
                        backgroundImage: `url(${promotion.brandImage})`,
                      }"></div>
                      <div class="core__txt">
                        <div class="core__value">
                          {{ promotion.point }} điểm
                        </div>
                        <div class="core__date">
                          {{ promotion.expire_duration }}
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </div>
        <div v-else class="promotion__empty">
          <b-row>
            <b-col>
              <p class="h4 text-center">
                Rất tiếc!!! Chưa tìm thấy kết quả phù hợp, vui lòng thử lại
              </p>
            </b-col>
          </b-row>
        </div>

        <b-row v-if="rowsVoucher.length > 0">
          <b-col>
            <b-pagination class="pt-1 pb-3" :total-rows="serverParams.totalRows" :per-page="serverParams.pageSize"
              v-model="serverParams.currentPage" first-number last-number align="center">
            </b-pagination>
          </b-col>
        </b-row>

        <!-- voucher Vip -->
        <div class="promotion__top" v-if="dataVipVoucher">
          <div class="promotion__title">
            <h2>ƯU ĐÃI VIP</h2>
            <p>{{ VipVoucher }}</p>
          </div>
        </div>
        <div>
          <b-row cols="2" cols-lg="3" class="wrap-promation">
            <b-col v-for="(promotion, index) in dataVipVoucher" :key="index" class="warp-col">
              <b-card class="m-0 p-0">
                <a :href="`/club/voucher-detail/${promotion.voucher_id}`" class="promotion__item">
                  <div class="promotion__money">
                    {{ formatPrice(promotion.price) }} đ
                  </div>
                  <div class="fs-bg cmBg promotion__bg" :style="{ backgroundImage: `url(${promotion.image})` }"></div>
                  <div class="fs-txt promotion__txt">
                    <h3>{{ promotion.title }}</h3>
                  </div>
                  <div class="core__block">
                    <div class="fs-bg cmBg core__bg" :style="{
                      backgroundImage: `url(${promotion.brandImage})`,
                    }"></div>
                    <div class="core__txt">
                      <div class="core__value">{{ promotion.point }} điểm</div>
                      <div class="core__date">
                        {{ promotion.expire_duration }}
                      </div>
                    </div>
                  </div>
                </a>
              </b-card>
            </b-col>
          </b-row>
        </div>
        <b-row v-if="vipVoucherParams.totalPages > 1">
          <b-col>
            <b-pagination :total-rows="vipVoucherParams.totalRows" :per-page="vipVoucherParams.pageSize"
              v-model="vipVoucherParams.currentPage" align="center" first-number last-number
              @change="getFillteredVouchers">
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>

    <div :class="'popup-overlay ' + modalClass">
      <!-- popup promotion -->
      <b-container v-if="currentVoucherDetail">
        <div class="popup-inr message-box promotion__popup has__close__circle"
          v-if="currentPopup == PopupLoyalty.PromotionDetail">
          <div class="close-popup" @click="closePopup()"></div>
          <div class="fs-box">
            <div class="fs-pic pic__promotion">
              <img class="fs-pc" :src="currentVoucherDetail.images_rectangle[640]" :alt="currentVoucherDetail.title" />
              <img class="fs-sp" :src="currentVoucherDetail.images_rectangle[640]" :alt="currentVoucherDetail.title" />
            </div>

            <div class="box__info">
              <div class="avt__info">
                <div class="fs-pic">
                  <img :src="currentVoucherDetail.brandImage" :alt="currentVoucherDetail.title" />
                </div>
              </div>
              <div class="txt__info fs-pc">
                <div class="title__info">
                  {{ currentVoucherDetail.title }}
                </div>
                <div class="date__info">
                  <span class="type__info">{{ currentVoucherDetail.point }} điểm</span>
                  <span class="date">{{
                    formatDate(currentVoucherDetail.expired_date)
                  }}</span>
                </div>
              </div>
              <div class="buts__info">
                <button type="button" class="but-use" @click="showPopup(PopupLoyalty.QRCodeDetail)">
                  Sử dụng
                </button>
              </div>
            </div>

            <div class="txt__info fs-sp">
              <div class="title__info">{{ currentVoucherDetail.title }}</div>
              <div class="date__info">
                <span class="type__info">{{ currentVoucherDetail.point }} điểm</span>
                <span class="date">{{
                  formatDate(currentVoucherDetail.expired_date)
                }}</span>
              </div>
            </div>

            <div class="tab__box">
              <div class="nav__tab">
                <ul>
                  <li :class="
                    currentTabPopup == tabActivePopup.Condition
                      ? 'active'
                      : ''
                  " @click="activeTabPopup(tabActivePopup.Condition)">
                    ĐIỀU KIỆN ÁP DỤNG
                  </li>
                  <li :class="
                    currentTabPopup == tabActivePopup.Info ? 'active' : ''
                  " @click="activeTabPopup(tabActivePopup.Info)">
                    THÔNG TIN CHUNG
                  </li>
                </ul>
              </div>
              <div class="content__tab">
                <div :class="`item__content__tab list__brief ${currentTabPopup == tabActivePopup.Condition ? 'active' : ''
                  }`" v-html="currentVoucherDetail.note"></div>
                <div :class="`item__content__tab ${currentTabPopup == tabActivePopup.Info ? 'active' : ''
                  }`" v-html="currentVoucherDetail.content"></div>
              </div>
            </div>
          </div>
        </div>
      </b-container>

      <b-container v-if="currentVoucherDetail != null">
        <!-- popup nhận ưu đãi thành công -->
        <div class="popup-inr message-box endow__success has__close__circle"
          v-if="currentPopup == PopupLoyalty.QRCodeDetail">
          <div class="close-popup" @click="closePopup()"></div>
          <h2>Nhận ưu đãi thành công</h2>
          <div class="fs-box">
            <div class="fs-pic logo__ur">
              <img src="../assets/images/logo-ur.png" alt="urbox" />
            </div>
            <div class="box__qr">
              <div class="fs-pic">
                <img :src="currentVoucherDetail.image_code" alt="QR code" />
              </div>
              <div class="fs-txt">
                <p>{{ currentVoucherDetail.voucher_code }}</p>
              </div>
            </div>
            <div class="qr__brief">
              <p>
                Quý khách vui lòng mở trực tiếp ưu đãi trên <br />web/app/tin
                nhắn cho nhân viên khi thanh toán <br />(không đưa ảnh chụp màn
                hình)
              </p>
              <p>
                <strong>Hạn sử dụng:
                  {{ formatDate(currentVoucherDetail.expired_date) }}
                </strong>
              </p>
              <p><strong>Hotline: 1900 299 232</strong></p>
            </div>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { Navigation } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import useJwt from "@/auth/jwt/useJwt";
import moment from "moment";
import Select2 from "v-select2-component";
import {
  BCard,
  BRow,
  BCol,
  BContainer,
  BPagination,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
  BModal,
  BButton,
} from "bootstrap-vue";

import { listRankLoyalty } from "../mockData/club";
import { isMobile } from "mobile-device-detect";
import axios from "axios";

// Import Swiper styles
import "swiper/swiper-bundle.css";
import "../assets/css/popup.css";

SwiperCore.use([Navigation]);

const PopupLoyalty = {
  Login: 1,
  Otp: 2,
  InputWrongPhoneOrOtp: 3,
  InputWrongMoreThan3Time: 4,
  NotSentOtp: 5,
  PromotionDetail: 6,
  QRCodeDetail: 7,
  ExchangePoints: 8,
  ExchangeFail601: 9,
  ExchangeFail602: 10,
  ExchangeFail603: 11,
  ExchangeFail604: 12,
};

const tabActivePopup = {
  Condition: 1,
  Info: 2,
};

export default {
  name: "Club",
  data() {
    return {
      isShowLogin: false,
      customerInfo: null,
      BannerButton: null,
      BannerMain: null,
      BannerPromotion: null,
      PromotionForYou: null,
      RecievedPromotion: null,
      VipVoucher: null,
      dataExchangedVoucher: [],
      currentVoucherDetail: null,
      currentTabPopup: tabActivePopup.Condition,
      tabActivePopup,

      getCities: [],
      listRankLoyalty: listRankLoyalty.map((item) => {
        return {
          id: item.id,
          text: item.name,
        };
      }),
      getVoucherCategory: [],
      PopupLoyalty,
      currentPopup: null,
      isShowPopupLogin: false,
      modalClass: "",
      tokenLoyalty: null, 
      serverParams: {
        currentPage: 1,
        pageSize: 6,
        totalRows: 0,
        categoryId: null,
        cityId: null,
        pointId: null,
        rankId: 0,
        voucherId: 0,
        totalPages: 1,
        search: ''
      },

      cliensParams: {
        currentPage: 1,
        pageSize: 15,
      },
      vipVoucherParams: {
        currentPage: 1,
        pageSize: 20,
        totalRows: 0,
        totalPages: 1,
      },
      dataVipVoucher: null,

      rowsVoucher: [],
      isMobile: isMobile,

      loginLoyalty: {
        Phone: "",
        Otp: "",
      },
      swiper: null,
      isEndSlide: false,
      activeIndexSide: 0,
      currentSnap: 0,
      snapGrid: [],
      myTimeout: null,
    };
  },

  watch: {

    // search(newSearch, oldSearch) {
    //   this.getSearchVouchers();
    // },
    serverParams: {
      handler(val) {
        if (this.serverParams.search.length != 0) {
          this.serverParams.categoryId = 0;
        } 
        this.getFillteredVouchers();
      },
      deep: true,
    },
  },

  methods: {
    async getCategories() {
      try {
        const res = await useJwt.get("club/categories");
        this.getVoucherCategory = res.data.result.map((item) => {
          return {
            id: item.id,
            text: item.name,
          };
        });
        this.getVoucherCategory.unshift({
          id: 0,
          text: "Tất cả",
        });
      } catch (error) {
        this.$toast.error(
          "Có lỗi trong khi lấy dữ liệu danh mục Itel club, Quý khách vui lòng thử lại!",
          { icon: true, closeButton: "button" }
        );
      }
    },

    async getBannerLoyalty() {
      try {
        const res = await useJwt.post("club/get-banner-loyalty", {
          columnFilters: {},
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: 1,
        });
        if (res.data.code == 200) {
          this.BannerMain = res.data.result.BannerMain;
          this.BannerPromotion = res.data.result.BannerPromotion;
          this.BannerButton = res.data.result.BannerButton;
          this.PromotionForYou = res.data.result.PromotionForYou;
          this.RecievedPromotion = res.data.result.RecievedPromotion;
          this.VipVoucher = res.data.result.VipVoucher;
        }
      } catch (error) {
        this.$toast.error(
          "Có lỗi trong khi lấy dữ liệu Itel club, Quý khách vui lòng thử lại!",
          { icon: true, closeButton: "button" }
        );
      }
    },

    async getFillteredVouchers() {
      // 
      try {
        // this.loading(true); 
        const res = await useJwt.post(
          `club/filtered-voucher?page=${this.serverParams.currentPage}&pageSize=${this.serverParams.pageSize}`,
          {
            categoryId: this.serverParams.categoryId,
            cityId: this.serverParams.cityId,
            pointId: this.serverParams.pointId,
            rankId: this.serverParams.rankId,
            voucherId: this.serverParams.voucherId,
            title: this.serverParams.search
          }
        );
        // this.loading(false); 
        if (res.data.code == 200 && res.data.result.data) {
          this.rowsVoucher = res.data.result.data.content;
          this.serverParams.totalRows = res.data.result.data.totalElements;
        }
        else {
          this.rowsVoucher = [];
          this.serverParams.totalRows = 0;
        }
      } catch (error) {
        this.loading(false);
        this.$toast.error(
          "Có lỗi trong khi lấy dữ liệu Itel club, Quý khách vui lòng thử lại!",
          { icon: true, closeButton: "button" }
        );
      }
    },
    async getExchangedVoucherUser() {
      // console.log('tokenLoyalty',this.tokenLoyalty)
      try {
        const res = await useJwt.get(
          `club/client?page=${this.cliensParams.currentPage}&pageSize=${this.cliensParams.pageSize}`,
          {
            headers: {
              apiKey: `${this.tokenLoyalty}`,
            },
          }
        );
        if (res.data.code == 200) {
          // const data = res.data.result.data.content.sh
          this.dataExchangedVoucher = res.data.result.data.content.sort(
            (a, b) => b.transaction_id - a.transaction_id
          );
        } else {
          this.$toast.error(
            "Có lỗi trong khi lấy dữ liệu Itel club, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );
        }
      } catch (error) {
        // this.$toast.error(
        //   "Có lỗi trong khi lấy dữ liệu Itel club, Quý khách vui lòng thử lại!",
        //   { icon: true, closeButton: "button" }
        // );
      }
    },

    async fetchCities() {
      try {
        const res = await useJwt.post("getMasterData/Cities", {
          columnFilters: {},
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: 1,
        });

        this.getCities = res.data.result.map((item) => {
          return {
            id: item.id,
            text: item.text,
          };
        });
        this.getCities.unshift({
          id: 0,
          text: "Tất cả",
        });
      } catch (error) {
        this.$toast.error(
          "Có lỗi trong khi lấy dữ liệu danh mục Itel club, Quý khách vui lòng thử lại!",
          { icon: true, closeButton: "button" }
        );
      }
    },

    onPageChanged(page) {
      console.log(page);
    },

    onShowLogin() {
      localStorage.redirectUrl = "/club";
      this.$router.push({ path: "/dang-nhap" });
    },

    reRegiterLoyaltyUser() {
      this.currentPopup = PopupLoyalty.Login;
    },

    regiterLoyaltyUser() {
      return useJwt.get(`otp/${this.loginLoyalty.Phone}`).then((response) => {
        this.currentPopup = PopupLoyalty.Otp;
      });
    },

    // async loginLoyaltyUser() {
    //   const params = {
    //     username: this.loginLoyalty.Phone,
    //     password: this.loginLoyalty.Otp,
    //   };
    //   try {
    //     const res = await useJwt.post("otp-login", params);
    //     this.tokenLoyalty = res.data.result.token;
    //     this.getCustomerInfo();
    //   } catch (error) {
    //     this.$toast.error(
    //       "Có lỗi trong khi lấy dữ liệu Itel club, Quý khách vui lòng thử lại!",
    //       { icon: true, closeButton: "button" }
    //     );
    //   }
    // },

    async getCustomerInfo() {
      try {
        const res = await useJwt.get("club/clients", {
          headers: {
            apiKey: `${this.tokenLoyalty}`,
          },
        });
        if (res.data.code == 200) {
          this.customerInfo = res.data.result.data;
          this.serverParams.rankId = res.data.result.data.rankId;
          // this.closePopup();
        }
      } catch (error) {
        this.$toast.error(
          "Có lỗi trong khi lấy dữ liệu Itel club, Quý khách vui lòng thử lại!",
          { icon: true, closeButton: "button" }
        );
      }
    },

    async getVoucherDetailExchange(transaction_id) {
      try {
        const res = await useJwt.get(
          `club/spend-transaction/${transaction_id}`,
          {
            headers: {
              apiKey: `${this.tokenLoyalty}`,
            },
          }
        );
        if (res.data.code == 200) {
          this.currentVoucherDetail = res.data.result.data;
          this.currentPopup = PopupLoyalty.PromotionDetail;
          this.modalClass = "active";
        }
      } catch (error) {
        this.$toast.error(
          "Có lỗi trong khi lấy dữ liệu Itel club, Quý khách vui lòng thử lại!",
          { icon: true, closeButton: "button" }
        );
      }
    },

    async activeTabPopup(tab) {
      this.currentTabPopup = tab;
    },

    showPopup(popup) {
      this.currentPopup = popup;
      this.modalClass = "active";
    },

    closePopup() {
      console.log("onClosePopup");
      this.modalClass = "";
      this.currentPopup = null;
    },

    showExchangedVoucherDetail(transaction_id) {
      this.getVoucherDetailExchange(transaction_id);
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    async getExchangedVoucherUser() {
      // console.log('tokenLoyalty',this.tokenLoyalty)
      try {
        const res = await useJwt.get(
          `club/client?page=${this.cliensParams.currentPage}&pageSize=${this.cliensParams.pageSize}`,
          {
            headers: {
              apiKey: `${this.tokenLoyalty}`,
            },
          }
        );
        if (res.data.code == 200) {
          // const data = res.data.result.data.content.sh
          this.dataExchangedVoucher = res.data.result.data.content.sort(
            (a, b) => b.transaction_id - a.transaction_id
          );
        } else {
          this.$toast.error(
            "Có lỗi trong khi lấy dữ liệu Itel club, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );
        }
      } catch (error) {
        // this.$toast.error(
        //   "Có lỗi trong khi lấy dữ liệu Itel club, Quý khách vui lòng thử lại!",
        //   { icon: true, closeButton: "button" }
        // );
      }
    },

    async getVipVoucherUser() {
      try {
        const res = await useJwt.post(
          `club/get-vip-voucher?page=${this.vipVoucherParams.currentPage}&pageSize=${this.vipVoucherParams.pageSize}`,
          {},
          {
            headers: {
              apiKey: `${this.tokenLoyalty}`,
            },
          }
        );
        if (res.data.code == 200) {
          this.dataVipVoucher = res.data.result.data.content;
          this.vipVoucherParams.totalRows = res.data.result.data.totalElements;
          this.vipVoucherParams.totalPages = res.data.result.data.totalPages;
        }
      } catch (error) {
        this.$toast.error(
          "Có lỗi trong khi lấy dữ liệu Itel club, Quý khách vui lòng thử lại!",
          { icon: true, closeButton: "button" }
        );
      }
    },
    lstore() {
      return localStorage;
    },

    onSwiper(swiper) {
      this.swiper = swiper;
      this.snapGrid = swiper.snapGrid;
    },

    onNextVoucher() {
      this.swiper.slideNext();
    },

    onPrevVocuher() {
      this.swiper.slidePrev();
    },

    onSlideChange(swiper) {
      this.currentSnap = swiper.snapIndex;
      this.isEndSlide = swiper.isEnd;
      this.activeIndexSide = swiper.activeIndex;
    },
    onSnap(index) {
      this.swiper.slideTo(index);
    },
  },

  mounted() {
    if (localStorage.token) {
      this.tokenLoyalty = localStorage.token;
      this.getCustomerInfo();
      this.getExchangedVoucherUser();
    }
    this.getBannerLoyalty();
    this.getCategories();
    this.getFillteredVouchers();
    this.fetchCities();
    this.getVipVoucherUser();
    this.lstore();
  },

  components: {
    Swiper,
    SwiperSlide,
    Select2,
    BCard,
    BCol,
    BRow,
    BContainer,
    BPagination,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BModal,
    BButton,
  },
};
</script>


<style scoped>
/* my search */
.select2-custom {
  margin-right: -55px;
  width: 230px;
}

.sort__search {
  margin-left: 50px;
  position: relative;
  width: 440px;
  margin: 0 auto;
  opacity: 0.8;
}

.sort__input {
  border: 1px solid #eee;
  border-radius: 8px;
  width: 87%;
  padding: 12px 12px 12px 80px;
  box-sizing: border-box;
  position: relative;
  font-size: 16px;
  line-height: 1.5;
  flex: 1;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iOCIvPjxwYXRoIGQ9Ik0yMSAyMWwtNC00Ii8+PC9zdmc+);
  background-repeat: no-repeat;
  background-position: 25px;
}

/* my css */

.fs-container {
  overflow: hidden;
}

.warp-col {
  padding: 10px;
}

.wrap-promation {
  margin: 1px;
}

.member_name_comom {
  font-weight: 600;
  width: 100%;
  font-size: 18px;
  text-align: center;
}

.swiper-prev {
  position: absolute;
  left: -5rem;
  margin-top: -9vw;
  z-index: 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.swiper-next {
  position: absolute;
  right: -5rem;
  margin-top: -9vw;
  z-index: 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.wrapSnap {
  /* margin: 0 4px 0 4px; */
}

.snap {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: rgb(132, 111, 111);
  cursor: pointer;
}

.snap-active {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #fff;
}

.discover-banner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.banner-img {
  width: 60%;
  object-fit: contain;
}

.discover-buts {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 100%;
  /* margin-top: 20px; */
}

.prev-item {
  background-color: #fff;
  border: 1px solid #ccc;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: -40px;
  transform: translateY(-50%);
}

.nav__inr,
.member__inr,
.discover__inr__box,
.voucher__inr,
.promotion__inr,
.endow__inr {
  width: 120rem;
}

.member__inr,
.discover__inr__box,
.voucher__inr,
.promotion__inr,
.endow__inr {
  padding: 0 0 1% 0;
}

.nav__inr {
  padding: 3.4rem 0 2rem 0;
}

.nav__inr .nav-news {
  margin: 0;
}

.section__member {
  padding: 2rem 0 0 0;
}

.member__inr {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 3rem 0;
  box-shadow: 0 10px 30px rgba(209, 213, 223, 0.5);
  margin-bottom: 1.4rem;
}

.member__item {
  margin-left: 16%;
}

.member__item p {
  font-size: 2rem;
  color: #141821;
  font-weight: 700;
}

.member__item p.color__red {
  color: #ed1d24;
}

.has__start {
  padding-left: 4rem;
}

.start__red {
  background: url(../assets/images/club/start__red.png) no-repeat;
  background-size: 3.5rem auto;
  background-position: left center;
}

.start__silver {
  background: url(../assets/images/club/start__silver.png) no-repeat;
  background-size: 3.5rem auto;
  background-origin: left center;
}

.start__yellow {
  background: url(../assets/images/club/start__yellow.png) no-repeat;
  background-size: 3.5rem auto;
  background-origin: left center;
}

.start__diamond {
  background: url(../assets/images/club/start__diamond.png) no-repeat;
  background-size: 3.5rem auto;
  background-origin: left center;
}

.voucher__box {
  position: relative;
}

.voucher__box .swiper-pagination {
  left: 30rem;
  bottom: 2.5rem;
}

.discover__txt {
  flex: 0 0 82%;
  max-width: 82%;
  display: flex;
  justify-content: space-between;
  /* background-color: #999999; */
  align-items: center;
}

.discover__title {
  width: 100%;
  height: 100%;
  padding-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.discover__title p {
  font-size: 2rem;
  color: #141821;
  font-weight: 700;
}

.discover__txt .login__but button {
  width: 15rem;
  padding: 0;
  -webkit-border-radius: 0.5rem;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  height: 5rem;
  text-transform: initial;
}

.endow__block {
  background-color: #ed1d24;
  padding: 3rem 10rem;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 25rem;
  top: -20rem;
  margin-bottom: -20rem;
}

.endow__block,
.endow__box {
  display: block;
  width: 100%;
}

.endow__title {
  font-size: 3.6rem;
  color: #fff;
  font-weight: 700;
  margin-bottom: 2rem;
}

.endow__title h1,
.endow__title h2,
.endow__title h3,
.endow__title h4,
.endow__title h5,
.endow__title h6 {
  text-transform: uppercase;
  color: #fff;
}

.endow__title p {
  font-size: 1.6rem;
  color: #fff;
  font-weight: 400;
}

.endow__block .swiper-slide {
  background-color: transparent;
}

.endor__item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 2.8rem 1rem 2.8rem 3rem;
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #fff;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
  cursor: pointer;
}

.endor__item:last-child {
  margin-bottom: 0;
}

.endor__item::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 86%;
  left: 27%;
  top: 7%;
  border-left: 1px dashed #d9d9d7;
}

.endor__bg {
  flex: 0 0 18%;
  max-width: 18%;
  margin-right: 8%;
  border-radius: 1rem;
}

.endor__bg::before {
  padding-top: 100%;
}

.endor__txt {
  flex: 0 0 54%;
  max-width: 54%;
}

.endor__txt h3 {
  font-size: 2rem;
  color: #141821;
  font-weight: 700;
  margin-bottom: 1.2rem;
}

.endor__date {
  padding-left: 4.4rem;
  background: url(../assets/images/club/icon_date.png) no-repeat;
  color: #999999;
  font-weight: 500;
  font-size: 1.6rem;
  background-size: 1.6rem auto;
}

.endor__value {
  flex: 0 0 16%;
  max-width: 16%;
  font-size: 1.5rem;
  font-weight: 400;
  color: #fff;
  padding: 2rem 1rem;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
  background-color: #6adcd9;
  text-align: center;
}

.endow__box {
  position: relative;
}

.section__promotion {
  background-color: #f5f5f5;
  min-height: 40vw;
}

.promotion__top {
  display: flex;
  flex-wrap: wrap;
  padding: 5rem 0rem 4.4rem 0;
  justify-content: space-between;
}

.promotion__title {
  font-size: 3.6rem;
  color: #393e46;
  font-weight: 700;
}

.promotion__title h1,
.promotion__title h2,
.promotion__title h3,
.promotion__title h4,
.promotion__title h5,
.promotion__title h6 {
  text-transform: uppercase;
}

.promotion__title p {
  font-size: 1.6rem;
  color: #6f7885;
  font-weight: 400;
}

.promotion__sort {
  display: flex;
  justify-content: space-between;
}

.sort__col {
  margin-left: 3.5%;
}

.promotion__item {
  margin: 0 2% 2% 0;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
  overflow: hidden;
  background-color: #fff;
  padding-bottom: 3rem;
  box-shadow: 0 10px 30px rgba(209, 213, 223, 0.5);
}

.promotion__item:nth-child(3n) {
  margin: 0 0 2% 0;
}

.promotion__bg {
  margin-bottom: 0.2rem;
}

.promotion__bg::before {
  padding-top: 90%;
}

.promotion__money {
  font-size: 2rem;
  color: #fff;
  font-weight: 700;
  position: absolute;
  top: 1.7rem;
  right: 1rem;
  background-color: #ec1d22;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
  padding: 1rem;
  z-index: 1;
}

.promotion__txt,
.core__block {
  padding: 0 3rem;
}

.promotion__txt {
  margin-bottom: 2.2rem;
}

.promotion__txt h3 {
  font-size: 2rem;
  color: #141821;
  font-weight: 700;
  height: 6rem;
}

.core__block {
  display: flex;
  flex-wrap: wrap;
}

.core__bg {
  flex: 0 0 27%;
  max-width: 27%;
  margin-right: 8%;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
}

.core__bg::before {
  padding-top: 100%;
}

.core__value,
.core__date {
  font-size: 1.6rem;
  font-weight: 500;
}

.core__value {
  background: url(../assets/images/club/icon_core.png) no-repeat;
  color: #ec1d22;
  margin-bottom: 1rem;
  padding-left: 2.4rem;
  background-size: 1.6rem auto;
}

.core__date {
  padding-left: 2.4rem;
  background: url(../assets/images/club/icon_date.png) no-repeat;
  color: #999999;
  background-size: 1.6rem auto;
}

.news__promotion {
  background-color: #f5f5f5;
  padding: 0;
}

.promotion__detail {
  background-color: #f5f5f5;
  padding: 11rem 0 6.6rem 0;
}

.promotion__detail__inr {
  max-width: 117rem;
}

.core__txt {
  /* display: flex; */
  display: block;
  align-self: center;
}

.core__txt h3 {
  width: 100%;
  font-size: 2.4rem;
  color: #141821;
  font-weight: 700;
  margin-bottom: 1.4rem;
}

.tab__condition h4 {
  margin-bottom: 2rem;
  border-bottom: 1px solid #e5e5e5;
}

.tab__condition h4::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
  height: 2px;
  background-color: #ed1f24;
}

.tab__condition {
  flex: 0 0 30%;
  max-width: 30%;
}

.tab__info {
  flex: 0 0 67%;
  max-width: 67%;
  padding: 2.5rem 0;
  font-size: 1.4rem;
}

.tab__condition .banner-buts {
  padding-top: 1rem;
}

.banner-but {
  position: relative;
  display: inline-block;
  padding: 1.2rem;
  background-color: #ff2424;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff !important;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: 2px solid rgba(255, 36, 36, 0);
  cursor: pointer;
  text-align: center;
}

.card-body {
  padding: 0;
}

/* for-mobile */

@media screen and (max-width: 1100px) {

  .member__inr,
  .discover__inr__box,
  .voucher__inr,
  .endow__inr {
    width: 100%;
  }

  .promotion__inr {
    width: 100%;
  }

  .nav__inr {
    width: 100%;
    padding: 1vw 0 5.3333vw 0;
  }

  .discover__inr__box {
    padding: 1.7777vw 3.5vw 0 3.5vw;
    margin-bottom: 8vw;
  }

  .discover__pic {
    flex: 0 0 32%;
    max-width: 32%;
    margin-right: 4%;
  }

  .discover__pic img {
    margin: 0;
  }

  .discover__txt {
    flex: 0 0 64%;
    max-width: 64%;
    flex-wrap: wrap;
  }

  .discover__title {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    font-size: 3.5555vw;
    margin-bottom: 2.6666vw;
  }

  .discover__txt .banner-buts {
    flex: 0 0 100%;
    text-align: center;
    max-width: 100%;
  }

  .discover__txt .login__but button {
    font-size: 2.8444vw;
    height: auto;
    width: auto;
    padding: 2.2vw 5vw;
  }

  .member__inr {
    flex-wrap: wrap;
    padding: 3.2vw 8vw;
    justify-content: space-between;
    margin-bottom: 4vw;
  }

  .member__item {
    margin: 0;
  }

  .member__item:nth-child(1) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 3.2vw;
  }

  .member__item p {
    font-size: 2.84444vw;
  }

  .member__item p.member_name {
    font-weight: 400;
  }

  .has__start {
    padding-left: 7vw;
  }

  .start__red,
  .start__silver,
  .start__yellow,
  .start__diamond {
    background-size: 6vw auto;
  }

  .endow__box {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .endow__block {
    margin: 0;
    top: auto;
    -webkit-border-radius: 0;
    border-radius: 0;
    padding: 4.62222vw 3%;
  }

  .endor__item {
    padding: 6vw 3% 6vw 6%;
    margin-bottom: 2.222vw;
  }

  .endor__txt h3 {
    font-size: 3.911vw;
    margin-bottom: 2.666vw;
  }

  .endor__date {
    padding-left: 4.8vw;
    background-size: 3.37777vw auto;
    font-size: 3.2vw;
  }

  .endor__value {
    padding: 3.5vw 2vw;
    font-size: 2.8vw;
  }

  .endow__title {
    font-size: 7.111vw;
    margin-bottom: 8vw;
  }

  .endow__title p {
    font-size: 2.8444vw;
  }

  .promotion__top {
    padding: 5.3333vw 3%;
    flex-wrap: wrap;
  }

  .promotion__title {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .promotion__sort {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: space-between;
  }

  .promotion__title {
    font-size: 7.111vw;
    margin-bottom: 0;
  }

  .promotion__title p {
    font-size: 2.8444vw;
    margin-bottom: 5vw;
  }

  .promotion__bg {
    margin-bottom: 3.37777vw;
  }

  .promotion__txt,
  .core__block {
    padding: 0 5%;
    margin: 0;
  }

  .promotion__txt h3 {
    font-size: 3.2vw;
    margin-bottom: 3vw;
    min-height: 9.5vw;
  }

  .core__txt {
    padding-top: 1vw;
    /* background-color: #6adcd9; */
  }

  .core__value,
  .core__date {
    font-size: 2.84444vw;
    background-size: 2.2vw auto;
    padding-left: 3.2vw;
  }

  .promotion__money {
    right: 1.3333vw;
    top: 1.3333vw;
    font-size: 4.26666vw;
    padding: 1.6666vw 3vw;
  }

  .promotion__detail__inr {
    width: 94%;
  }

  .promotion__detail {
    background-color: #fff;
    padding: 11.5vw 0;
  }

  .tab__condition,
  .tab__info {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .tab__box {
    font-size: 3.375vw;
    line-height: 1.6;
    display: none;
  }

  .tab__box.active {
    display: block;
  }

  .tab__box p {
    margin-bottom: 3vw;
  }

  .tab__box h4 {
    display: none;
  }

  .condition__list {
    padding: 3.2vw 1.777vw;
    max-height: initial;
  }

  .condition__list li {
    background-size: 5.3333vw auto;
    background-position: left 1vw;
    font-size: 3.375vw;
    margin-bottom: 1.2vw;
    padding-left: 9.06vw;
    padding: 1vw 0 1vw 9.06vw;
  }

  .tab__header {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
    flex: 0 0 100%;
  }

  .tab__item {
    position: relative;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 5% 3vw 5%;
    font-size: 3.375vw;
    font-weight: 700;
    text-transform: uppercase;
    color: #adadad;
  }

  .tab__item.active {
    color: #393e46;
  }

  .tab__item::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #ed1f24;
    opacity: 0;
  }

  .tab__item.active::after {
    opacity: 1;
  }

  .banner-but {
    padding: 0;
    font-size: 3.2vw;
    width: 100%;
    height: 10vw;
    -webkit-border-radius: 0;
    border-radius: 0;
  }

  .nav-news ul li h2 {
    padding: 2.8vw 2.8vw;
    font-size: 2.8vw;
  }

  .nav-news ul {
    padding: 0 1.8%;
  }

  .promotion__money {
    font-size: 1.3rem;
    color: #fff;
    font-weight: 700;
    text-align: end;
    margin-left: 30%;
    background-color: #ec1d22;
    -webkit-border-radius: 1rem;
    border-radius: 1rem;
    padding: 1rem;
    z-index: 1;
  }

  /* select 2 for mobile */
  /* Mobile */
  @media screen and (max-width: 520px) {
    .select2-custom {
      margin-right: -40px;
      width: 170px;
    }

    .sort__input {
      padding-left: 70px;
      width: 41%;
    }

    .endow__title {
      margin-bottom: 0rem;
    }

    .member__inr {
      margin-top: 14px;
      border-radius: 5px;
    }

    .nav__inr {
      padding: 0 0 0 0;
    }

    .nav__inr .nav-news {
      margin: 0;
    }

    .nav-news ul {
      padding: 0 0 0 0;
    }

    .nav-news ul li a {
      padding: 1.2vw 1.2vw;
      font-size: 2.8vw;
    }

    .nav-news ul li h2 {
      font-size: 1.1rem;
      margin-left: 0;
    }

    .discover-banner {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .banner-img {
      width: 100%;
      object-fit: contain;
    }

    .discover-buts {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      /* margin-top: 20px; */
    }

    .discover__title p {
      font-size: 100%;
      color: #141821;
      font-weight: 700;
    }

    .banner-but {
      font-size: 1.2rem;
      width: 80%;
      -webkit-border-radius: 1rem;
      border-radius: 1rem;
    }

    .fs-but {
      position: relative;
      display: inline-block;
      padding: 1.2rem 5.55rem;
      background-color: #ff2424;
      text-transform: uppercase;
      font-size: 1.8rem;
      font-weight: 500;
      color: #fff !important;
      -webkit-border-radius: 10px 4px 10px 4px;
      border-radius: 10px 4px 10px 4px;
      border: 2px solid rgba(255, 36, 36, 0);
      cursor: pointer;
      text-align: center;
    }

    .promotion__money {
      font-size: 4.26666vw;
      padding: 1.6666vw 3vw;
    }

    .nav__tab li {
      /* margin-right: 0; */
      font-size: 1rem;
    }

    .snap {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: rgb(132, 111, 111);
      cursor: pointer;
    }

    .snap-active {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: #fff;
    }

    .sort__col {
      margin-left: -2%;
      ;
      padding: 2% 2% 0 2%;
    }

    .warp-col {
      padding: 5px;
    }

    .wrap-promation {
      margin: 4px;
    }

    .card-body {
      padding: 0;
    }
  }
}
</style>
<style>
.modal-header .close {
  display: none;
}
</style>

